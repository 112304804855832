import './style.scss';
import '../card-reference/style.scss';


var swiperRefInstance = null; // Variable globale pour garder l'instance du Swiper

var initBlockRef = function() {
    var SlideInblocks = document.querySelectorAll('.wp-block-edeis-references-list');

    SlideInblocks.forEach(block => {
        // Vérifie la taille de l'écran pour déterminer si on initialise le slider ou l'accordéon
        if (window.innerWidth <= 768) {
        	destroySliderRef();
            initSliderRef(block);
        } else {
            destroySliderRef(); // Détruire le slider si présent en mobile
        }
    });
};
var initSliderRef = function(block) {
    var swiperContainer = block.querySelector('.swiper');
    
    if (!swiperContainer) {
        return;
    }
    
    swiperRefInstance = new Swiper(swiperContainer, { // Utilise la variable globale ici
        direction: 'horizontal', // Slider horizontal pour desktop
        loop: false,
        speed: 1000, // Ajuste la vitesse de défilement
        slidesPerView: 1, // Nombre de slides visibles
        spaceBetween: 30, // Espace entre les slides
        grabCursor: false,
        mousewheel: {
            enabled: true,
            releaseOnEdges: true,
        },
        autoHeight:false,
        on: {
            reachEnd: function () {
                console.log('Fin du slider atteinte');
                // Logique pour reprendre le scroll de la page si nécessaire
            },
        },
    });
};

// Fonction pour détruire l'instance du slider Swiper
var destroySliderRef = function() {
    if (swiperRefInstance !== null) {
        swiperRefInstance.destroy(true, true); // Détruire l'instance et supprimer les éléments du DOM associés
        swiperRefInstance = null; // Réinitialiser la variable après destruction
    }
};

// Fonction de déclenchement au chargement du DOM
document.addEventListener('DOMContentLoaded', () => {
    initBlockRef();

    // Gestion pour l'aperçu dans l'éditeur Gutenberg avec ACF
    if (window.acf) {
        window.acf.addAction('render_block_preview/type=references-list', function() {
            initBlockRef();
        });
    }

    // Gestion du redimensionnement
    window.addEventListener('resize', () => {
        initBlockRef(); // Réinitialise le comportement lors du redimensionnement
    });
});
